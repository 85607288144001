import React from "react";
// import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import { PageHeader01, Article03 } from "gatsby-theme-container";
import headerData from "@lendex/shared/constants/header-01";
import menuData from "@lendex/shared/constants/menu-01";
import footerData from "@lendex/shared/constants/footer-01";
import contactData from "@lendex/shared/constants/contact";
// images
import BlogImage1 from "@lendex/shared/images/blog-feed-img-1.webp";
import BlogImage2 from "@lendex/shared/images/blog-feed-img-2.webp";
import BlogImage3 from "@lendex/shared/images/blog-feed-img-3.webp";
import BlogImage4 from "@lendex/shared/images/blog-details-content-inner-img-2.webp";

const allContent = {
    page_header_section: {
        pageName: "Blog Left Sidebar",
    },
    article_section: {
        items: [
            {
                id: 1,
                title: "Don't wait until you officially started business to line these up.",
                slug: "5-Steps-for-Competing-with-Digital-Goliaths",
                category: {
                    slug: "/category/google-adds",
                    title: "google adds",
                },
                author: {
                    slug: "/author/jowel-patwary",
                    username: "Jowel Patwary",
                },
                date: "08 July 2020",
                image: {
                    src: BlogImage1,
                },
                format: "image",
            },
            {
                id: 2,
                title: "Don't wait until you officially started business to line these up.",
                slug: "5-Steps-for-Competing-with-Digital-Goliaths",
                category: {
                    slug: "/category/google-adds",
                    title: "google adds",
                },
                author: {
                    slug: "/author/jowel-patwary",
                    username: "Jowel Patwary",
                },
                date: "08 July 2020",
                image: {
                    src: BlogImage2,
                },
                format: "image",
            },
            {
                id: 3,
                title: "5 Steps for Competing with Digital Goliaths",
                slug: "5-Steps-for-Competing-with-Digital-Goliaths",
                category: {
                    slug: "/category/google-adds",
                    title: "google adds",
                },
                author: {
                    slug: "/author/jowel-patwary",
                    username: "Jowel Patwary",
                },
                date: "08 July 2020",
                image: {
                    src: BlogImage3,
                },
                format: "image",
            },
            {
                id: 4,
                title: "5 Steps for Competing with Digital Goliaths",
                slug: "5-Steps-for-Competing-with-Digital-Goliaths",
                category: {
                    slug: "/category/google-adds",
                    title: "google adds",
                },
                author: {
                    slug: "/author/jowel-patwary",
                    username: "Jowel Patwary",
                },
                date: "08 July 2020",
                image: {
                    src: BlogImage4,
                },
                format: "image",
            },
            {
                id: 5,
                title: "Don't wait until you officially started business to line these up.",
                slug: "5-Steps-for-Competing-with-Digital-Goliaths",
                category: {
                    slug: "/category/google-adds",
                    title: "google adds",
                },
                author: {
                    slug: "/author/jowel-patwary",
                    username: "Jowel Patwary",
                },
                date: "08 July 2020",
                image: {
                    src: BlogImage1,
                },
                format: "image",
            },
            {
                id: 6,
                title: "5 Steps for Competing with Digital Goliaths",
                slug: "5-Steps-for-Competing-with-Digital-Goliaths",
                category: {
                    slug: "/category/google-adds",
                    title: "google adds",
                },
                author: {
                    slug: "/author/jowel-patwary",
                    username: "Jowel Patwary",
                },
                date: "08 July 2020",
                image: {
                    src: BlogImage2,
                },
                format: "image",
            },
        ],
    },
};

const Blog = () => (
    <Layout>
        {/* <SEO pageName="Demo Blog Grid" /> */}
        <HeaderOne hasBorder data={{ ...headerData, ...menuData }} />
        <PageHeader01 data={allContent.page_header_section} />
        <Article03 data={allContent.article_section} />
        <FooterOne data={{ ...footerData, ...contactData }} />
    </Layout>
);

export default Blog;
